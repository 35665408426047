import React from 'react';
import {
  useGetActiveFlightsSubscription,
  GetActiveFlightsSubscription,
  useSetSelectedFlightPlanMutation,
} from '@/generated/graphql';
import ActiveFlights from '@/src/components/ActiveFlights/index';
import {
  ActiveFlightData,
  ActiveFlight,
} from '@/src/components/ActiveFlights/types';
import openRequestedWindow from '@/src/utils/openRequestedWindow';
import { useSession } from 'next-auth/client';
import { Skeleton } from '@chakra-ui/core';

type ActiveFlightsType = GetActiveFlightsSubscription['activeFlights'];
type ActiveFlightType = ActiveFlightsType[0];

const parseActiveFlight = (data: ActiveFlightType): ActiveFlight => {
  const vehicleStatuses = data.flightPlan?.vehicle?.vehicleStatuses;
  const estimatedSocPercentage =
    vehicleStatuses && vehicleStatuses.length > 0
      ? vehicleStatuses[0].estimatedSocPercentage
      : undefined;

  return {
    batteryPercentage: estimatedSocPercentage || 0,
    flightRouteName: data.flightPlan?.path?.title || 'NO FLIGHT ROUTE TITLE',
    flightStatus: data.flightStatus,
    vehicleName: data.flightPlan?.vehicle?.name || 'NO VEHICLE NAME',
    networkName: data.flightPlan?.network?.title || 'NO NETWORK TITLE',
    flightPlanId: data.flightPlan?.id || NaN,
  };
};

const ActiveFlightsConnected = () => {
  /** HOOKS START */
  const [session] = useSession();

  const { data, loading, error } = useGetActiveFlightsSubscription();

  const [setSelectedFlightPlan] = useSetSelectedFlightPlanMutation({
    onCompleted: () => {
      openRequestedWindow(`${window.location.origin}/vehicle`, 'vehicle');
    },
  });
  /** HOOKS END */

  // :TODO error state
  if (error) {
    return null;
  }

  const activeFlights: ActiveFlightData =
    data?.activeFlights && !loading
      ? data?.activeFlights.map(parseActiveFlight)
      : [];

  return (
    <Skeleton isLoaded={!loading}>
      <ActiveFlights
        data={activeFlights}
        onClick={(flightPlanId) => {
          setSelectedFlightPlan({
            variables: {
              auth0UserId: session.user?.id,
              flightPlanId,
            },
          });
        }}
      />
    </Skeleton>
  );
};

export default ActiveFlightsConnected;
